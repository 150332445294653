import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* Scroll to top button */}
      {isVisible && (
        <Link
          to="start"
          smooth={true}
          duration={500}
          className="fixed bottom-5 right-5 bg-black dark:bg-gray-800 text-white dark:text-gray-200 p-3 rounded-full shadow-lg cursor-pointer transition-opacity duration-300 hover:opacity-80 dark:hover:opacity-90"
          aria-label="Scroll to Top"
        >
          ↑
        </Link>
      )}
    </>
  );
};

export default ScrollToTopButton;
