const About = () => {
    return (
        <div id="about" className="bg-gray-100 dark:bg-gray-800 py-16">
            <div className="max-w-screen-xl mx-auto text-center">
                <h2 className="text-4xl font-bold text-black dark:text-white mb-6">
                    About Me
                </h2>
                <p className="text-lg text-gray-700 dark:text-gray-300">
                    Hi, I'm Dragan Milinkovic, an 18-year-old Electrical Technician of Information Technologies from Serbia.
                    Ever since I was young, technology has been my passion, and I have been honing my skills to create amazing digital solutions.
                </p>
                <p className="text-lg text-gray-700 dark:text-gray-300 mt-4">
                    I specialize in developing high-quality, custom software solutions tailored to your needs.
                    I always strive to deliver the best of the best because I believe in perfection. If you're looking for someone
                    who's dedicated, skilled, and passionate about their craft, you're in the right place!
                </p>
            </div>
        </div>
    );
};

export default About;
