import { FaCheckCircle } from 'react-icons/fa';

const BenefitsSection = () => (
    <div className="bg-gray-50 dark:bg-gray-800 py-16">
        <div className="max-w-screen-xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-black dark:text-white mb-8">
                Why Choose Me?
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
                {/* Benefit 1 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Delivering High-Quality Results
                    </p>
                </div>

                {/* Benefit 2 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Clean, Well-Structured Code
                    </p>
                </div>

                {/* Benefit 3 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Easy to Upgrade and Maintain
                    </p>
                </div>

                {/* Benefit 4 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Lifetime Support for All Projects
                    </p>
                </div>

                {/* Benefit 5 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Free Hosting for 1st Year (For Bots)
                    </p>
                </div>

                {/* Benefit 6 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Competitive and Reasonable Pricing
                    </p>
                </div>

                {/* Benefit 7 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Always Using the Latest Technology
                    </p>
                </div>

                {/* Benefit 8 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Extensive Experience in the Industry
                    </p>
                </div>

                {/* Benefit 9 */}
                <div className="flex items-center space-x-4">
                    <FaCheckCircle className="text-green-500 w-8 h-8" />
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        Fast Delivery with High Efficiency
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default BenefitsSection;
