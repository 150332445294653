import React from "react";
import Hero from "./components/Hero";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import TechnologyStack from "./components/TechnologyStack";
import Benefits from "./components/Benefits";
import Contact from "./components/Contact";
import FAQ from "./components/FAQ";
import About from "./components/About";
import ScrollToTopButton from "./components/ScrollToTopButton";

function App() {
  return (
    <div
      id="start"
      className="font-sans bg-white dark:bg-gray-900 text-black dark:text-white min-h-screen"
    >
      <ScrollToTopButton />
      <Header />
      <Hero />
      <About />
      <Benefits />
      <TechnologyStack />
      <Portfolio />
      <FAQ />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
