import React from "react";

const Footer = () => (
  <footer className="w-full py-4 bg-white border-t dark:bg-gray-800">
    <div className="text-center text-sm text-gray-500 dark:text-gray-400">
      <p className="mt-2">Made with ❤️ by DRANGULA</p>
      <br />
      <p>© {new Date().getFullYear()} DRANGULA. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
