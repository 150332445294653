import { useState } from 'react';

const Contact = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        company: '',
        contact: '',
        service: 'Discord Bot',
        bodyText: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { fullName, bodyText } = formData;

        if (!fullName || !bodyText) {
            alert("Please fill out all required fields.");
            return;
        }

        const { company, service, contact } = formData;
        const subject = encodeURIComponent(`Quote Request from ${fullName}`);
        const body = encodeURIComponent(`Full Name: ${fullName}\nCompany/Organization: ${company}\nService: ${service}\nContact (Optional): ${contact}\nRequirements/Quote:\n\n${bodyText}`);
        const mailToLink = `mailto:hello@drangula.net?subject=${subject}&body=${body}`;
        window.location.href = mailToLink;
    };

    return (
        <div id="contact" className="bg-gray-50 dark:bg-gray-800 py-16">
            <div className="max-w-screen-xl mx-auto text-center">
                <h2 className="text-4xl font-bold text-black dark:text-white mb-8">
                    Get in Touch for a Quote
                </h2>

                <div className="max-w-3xl mx-auto bg-white dark:bg-gray-700 p-8 rounded-lg shadow-lg">
                    <form onSubmit={handleSubmit}>
                        {/* Full Name */}
                        <div className="mb-4">
                            <label htmlFor="fullName" className="block text-lg font-medium text-gray-700 dark:text-gray-300">
                                Full Name <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                placeholder="John Doe"
                                required
                            />
                        </div>

                        {/* Company/Organization */}
                        <div className="mb-4">
                            <label htmlFor="company" className="block text-lg font-medium text-gray-700 dark:text-gray-300">
                                Company/Organization
                            </label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                placeholder="Example Corp"
                            />
                        </div>

                        {/* Contact */}
                        <div className="mb-4">
                            <label htmlFor="contact" className="block text-lg font-medium text-gray-700 dark:text-gray-300">
                                Contact <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                id="contact"
                                name="contact"
                                value={formData.contact}
                                onChange={handleChange}
                                placeholder="Discord/Telegram/WhatsApp"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                required
                            />
                        </div>

                        {/* Service Dropdown */}
                        <div className="mb-4">
                            <label htmlFor="service" className="block text-lg font-medium text-gray-700 dark:text-gray-300">
                                Select Service <span className="text-red-500">*</span>
                            </label>
                            <select
                                id="service"
                                name="service"
                                value={formData.service}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
                            >
                                <option value="Discord Server">Discord Server</option>
                                <option value="Discord Bot">Discord Bot</option>
                                <option value="Telegram Bot">Telegram Bot</option>
                                <option value="Something else">Something else</option>
                            </select>
                            <div className="text-gray-500 mt-1 text-sm dark:text-gray-400">
                                <p>Starting from $125</p>
                            </div>
                        </div>

                        {/* Requirements/Quote */}
                        <div className="mb-4">
                            <label htmlFor="bodyText" className="block text-lg font-medium text-gray-700 dark:text-gray-300">
                                Requirements <span className="text-red-500">*</span>
                            </label>
                            <textarea
                                id="bodyText"
                                name="bodyText"
                                value={formData.bodyText}
                                onChange={handleChange}
                                rows="4"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                placeholder="Looking for a Discord bot to automate moderation, handle welcome messages, and manage custom commands. Include a payment integration system and a leaderboard feature."
                                required
                            />
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full py-3 mt-4 bg-black text-white text-lg font-semibold rounded-lg shadow-md hover:bg-gray-800"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
