import React, { useState } from "react";

const Portfolio = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const portfolioItems = [
    {
      title: "Project 1",
      description: "Description of project 1",
      imageUrl: "https://component.gallery/static/7f4dabad1abbb0bdbfdb537ab45c013d/Skeleton%20icon..svg",
    },
    {
      title: "Project 2",
      description: "Description of project 2",
      imageUrl: "https://component.gallery/static/7f4dabad1abbb0bdbfdb537ab45c013d/Skeleton%20icon..svg",
    },
    {
      title: "Project 3",
      description: "Description of project 3",
      imageUrl: "https://component.gallery/static/7f4dabad1abbb0bdbfdb537ab45c013d/Skeleton%20icon..svg",
    },
    {
      title: "Project 4",
      description: "Description of project 4",
      imageUrl: "https://component.gallery/static/7f4dabad1abbb0bdbfdb537ab45c013d/Skeleton%20icon..svg",
    },
    {
      title: "Project 5",
      description: "Description of project 5",
      imageUrl: "https://component.gallery/static/7f4dabad1abbb0bdbfdb537ab45c013d/Skeleton%20icon..svg",
    },
    {
      title: "Project 6",
      description: "Description of project 6",
      imageUrl: "https://component.gallery/static/7f4dabad1abbb0bdbfdb537ab45c013d/Skeleton%20icon..svg",
    },
  ];

  return (
    <section id="portfolio" className="py-16 bg-gray-50 dark:bg-gray-800">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-black dark:text-white mb-12">
          Portfolio
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {portfolioItems.map((item, index) => (
            <div
              key={index}
              className="relative bg-white dark:bg-gray-700 rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-all duration-300"
            >
              <img
                src={item.imageUrl}
                alt={item.title}
                className="w-full h-64 object-cover cursor-pointer"
                onClick={() => handleImageClick(item.imageUrl)}
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-black dark:text-white">
                  {item.title}
                </h3>
                <p className="mt-2 text-gray-600 dark:text-gray-300">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative bg-white dark:bg-gray-900 rounded-lg p-4 max-w-3xl">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-black dark:text-white text-2xl font-bold"
            >
              ×
            </button>
            <img
              src={selectedImage}
              alt="Portfolio item"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Portfolio;
