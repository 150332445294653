import { Link } from "react-scroll";

const FAQ = () => (
    <div id="faq" className="bg-white dark:bg-gray-900 py-16">
        <div className="max-w-screen-xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-black dark:text-white mb-6">
                Frequently Asked Questions
            </h2>

            <div className="space-y-6">
                <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        How fast can you develop?
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">
                        It depends on the request, but I always aim to deliver as soon as possible. The faster, the better — "asap" is my motto!
                    </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        How can I pay?
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">
                        I accept PayPal, cryptocurrency, and credit card payments for your convenience.
                    </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        Do I get the source code?
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">
                        Of course! I believe in transparency, so you'll always have access to the source code of the project.
                    </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        Do I need hosting?
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">
                        Not at all! The first year of hosting is on me, so you can rest easy while I handle everything.
                    </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        What can I expect?
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">
                        You can expect nothing short of excellence. I only deliver the best of the best, tailored to your exact needs.
                    </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        More questions?
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">
                        Feel free to{" "}
                        <Link
                            style={{ cursor: "pointer" }}
                            className="text-blue-500 dark:text-blue-400"
                            to="contact"
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            contact me
                        </Link>{" "}
                        for any further inquiries. I’ll be happy to help!
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default FAQ;
