import React from "react";
import {
  FaGithub,
  FaPaypal,
  FaDiscord,
  FaTelegram,
  FaInstagram,
} from "react-icons/fa";
import logo from "../assets/drangula.jpg";
import { Link } from "react-scroll";
import CountUp from "react-countup";

const Hero = () => (
  <div className="flex flex-col items-center justify-start min-h-screen bg-white dark:bg-gray-900 text-black dark:text-white pt-20">
    {/* Hero Section Content with Border Container */}
    <div className="bg-white dark:bg-gray-800 border-4 border-gray-300 dark:border-gray-700 rounded-2xl shadow-lg p-10 w-full max-w-5xl mx-auto">
      <div className="flex flex-col items-center justify-start">
        <img src={logo} alt="Logo" className="w-32 h-32 rounded-full shadow-lg" />
        <h1 className="mt-6 text-4xl font-bold">Dragan Milinkovic</h1>
        <p className="mt-2 text-xl text-gray-600 dark:text-gray-400">Software Engineer</p>

        {/* Social Icons */}
        <div className="mt-6 flex space-x-6 justify-center flex-wrap">
          <a
            href="https://github.com/drangula"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black dark:text-white hover:opacity-80"
          >
            <FaGithub className="w-8 h-8" />
          </a>
          <a
            href="https://discord.com/drangula"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black dark:text-white hover:opacity-80"
          >
            <FaDiscord className="w-8 h-8" />
          </a>
          <a
            href="https://t.me/drangula"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black dark:text-white hover:opacity-80"
          >
            <FaTelegram className="w-8 h-8" />
          </a>
          <a
            href="https://www.instagram.com/drangulq/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black dark:text-white hover:opacity-80"
          >
            <FaInstagram className="w-8 h-8" />
          </a>
          <a
            href="https://paypal.me/drangulaa"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black dark:text-white hover:opacity-80"
          >
            <FaPaypal className="w-8 h-8" />
          </a>
        </div>

        {/* Buttons */}
        <div className="mt-6 space-x-4">
          <Link to="contact" smooth={true} offset={-70} duration={500}>
            <button className="px-6 py-2 font-medium border-2 border-black dark:border-white rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-all">
              Contact
            </button>
          </Link>

          <Link to="about" smooth={true} offset={-70} duration={500}>
            <button className="px-6 py-2 font-medium text-white bg-black dark:bg-gray-700 border-2 border-black dark:border-white rounded-lg hover:opacity-90 transition-all">
              Explore
            </button>
          </Link>
        </div>
      </div>

      {/* Statistics Section with Rounded Corners */}
      <div className="mt-16 bg-gray-100 dark:bg-gray-700 w-full py-12 rounded-2xl">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 max-w-screen-xl mx-auto px-4">
          <div className="text-center">
            <h2 className="text-4xl sm:text-3xl md:text-4xl font-bold">
              <CountUp start={0} end={1100} duration={2} suffix="+" />
            </h2>
            <p className="text-xl sm:text-lg text-gray-600 dark:text-gray-300">Clients world-wide</p>
          </div>

          <div className="text-center">
            <h2 className="text-4xl sm:text-3xl md:text-4xl font-bold">
              <CountUp start={0} end={6} duration={2} />
            </h2>
            <p className="text-xl sm:text-lg text-gray-600 dark:text-gray-300">Years of Experience</p>
          </div>

          <div className="text-center">
            <h2 className="text-4xl sm:text-3xl md:text-4xl font-bold">
              <CountUp start={0} end={300} duration={2} suffix="+" />
            </h2>
            <p className="text-xl sm:text-lg text-gray-600 dark:text-gray-300">Bots Developed</p>
          </div>

          <div className="text-center">
            <h2 className="text-4xl sm:text-3xl md:text-4xl font-bold">
              <CountUp start={0} end={800} duration={2} suffix="+" />
            </h2>
            <p className="text-xl sm:text-lg text-gray-600 dark:text-gray-300">Servers Built</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
