import React, { useState, useEffect } from "react";
import { Link } from "react-scroll"; // Import the Link component from react-scroll
import { SunIcon, MoonIcon } from "@heroicons/react/24/outline"; // Add Heroicons for icons

const Header = () => {

  const savedDarkMode = localStorage.getItem("isDarkMode") === "true";
  const [isDarkMode, setIsDarkMode] = useState(savedDarkMode);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    // Save the dark mode preference to localStorage whenever it changes
    localStorage.setItem("isDarkMode", isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="w-full py-4 bg-white dark:bg-gray-800 shadow-sm sticky top-0 z-50 border-b-[0.5px] border-gray-300 dark:border-gray-700">
      <nav className="container mx-auto flex justify-between items-center px-4 md:px-8">
        {/* Logo */}
        <Link to="start" smooth={true} duration={500}>
          <h1 className="text-2xl font-bold cursor-pointer text-black dark:text-white">
            DRANGULA
          </h1>
        </Link>

        {/* Navigation Links */}
        <ul className="hidden md:flex space-x-8">
          {["About", "Technology", "Portfolio", "FAQ"].map((section) => (
            <li key={section.toLowerCase()}>
              <Link
                to={section.toLowerCase()}
                smooth={true}
                offset={-70}
                duration={500}
                className="text-black dark:text-gray-200 font-medium hover:opacity-80 transition cursor-pointer"
              >
                {section}
              </Link>
            </li>
          ))}
        </ul>

        {/* Right Side Controls */}
        <div className="flex items-center space-x-4">
          {/* Dark Mode Toggle */}
          <button
            onClick={toggleDarkMode}
            className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80 transition"
          >
            {isDarkMode ? (
              <SunIcon className="w-6 h-6 text-yellow-400" />
            ) : (
              <MoonIcon className="w-6 h-6 text-gray-800" />
            )}
          </button>

          {/* Contact Button */}
          <Link to="contact" smooth={true} offset={-70} duration={500}>
            <button className="hidden md:block px-4 py-2 text-white bg-black dark:bg-gray-700 rounded-lg hover:opacity-90">
              Contact
            </button>
          </Link>

          {/* Mobile Menu Toggle */}
          <button onClick={toggleMenu} className="md:hidden text-black dark:text-white text-2xl">
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden fixed top-0 left-0 w-full h-full bg-white dark:bg-gray-800 shadow-lg z-10">
          <div className="flex justify-end p-4">
            <button onClick={toggleMenu} className="text-black dark:text-white text-2xl">
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>

          {/* Links */}
          <ul className="flex flex-col items-center space-y-6 mt-12 text-xl">
            {["About", "Portfolio", "FAQ", "Contact"].map((section) => (
              <li key={section.toLowerCase()}>
                <Link
                  to={section.toLowerCase()}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="text-black dark:text-gray-200 font-medium hover:opacity-80 transition cursor-pointer"
                  onClick={toggleMenu}
                >
                  {section}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;